<template>
  <div class="dance">
    <router-link to="/" class="back button mobile"
      ><img :src="require('@/assets/icon-back.svg')" alt="Back icon"
    /></router-link>

    <h1 v-if="dancer" class="text">
      {{ dancer.name }}
      <br />
      <span>in action</span>
    </h1>

    <div class="sidebar">
      <router-link to="/" class="sidebar__logo">
        <img
          alt="Poppr Christmas logo"
          :src="require('@/assets/christmas-logo.svg')"
        />
      </router-link>
      <div v-if="dancer" class="sidebar__text">
        <h1>
          {{ dancer.name }}
        </h1>
        <p>in action</p>
      </div>

      <router-link to="/" class="back button desktop">
        <img :src="require('@/assets/icon-back.svg')" alt="Back icon" />Go back
      </router-link>
    </div>

    <div v-if="dancer">
      <audio id="music" :autoplay="autoplay">
        <source :src="`./sounds/${dancer.key}.mp3`" type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
      <a href="#" @click.prevent="toggleMusic" class="music button">
        <span>Music on/off</span>
        <img
          class="turn-on"
          v-if="!isPlaying"
          :src="require('@/assets/icon-music-on.svg')"
          alt="Music on icon"
        />
        <img
          v-else
          :src="require('@/assets/icon-music-off.svg')"
          alt="Music off icon"
        />
      </a>
    </div>

    <model-viewer
      v-if="dancer"
      :src="`./models/${dancer.key}.glb`"
      ar
      ar-modes="webxr scene-viewer quick-look"
      ar-scale="auto"
      camera-controls
      autoplay
      alt="Christmas dance"
      :ios-src="`./models/${dancer.key}.usdz`"
    >
      <button class="button ar" slot="ar-button">
        <img :src="require('@/assets/icon-ar.svg')" alt="AR icon" />
        <span>View in AR</span>
      </button>
    </model-viewer>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    autoplay: { default: false },
  },
  data() {
    return {
      isPlaying: this.autoplay ? true : false,
      dancer: null,
      dancers: [
        {
          name: "Steven",
          key: "steven",
          img: "steven.jpg",
        },
        {
          name: "Frederik",
          key: "frederik",
          img: "frederik.jpg",
        },
        {
          name: "Bart",
          key: "bart",
          img: "bart.jpg",
        },
        {
          name: "Julie",
          key: "julie",
          img: "julie.jpg",
        },
        {
          name: "Ian",
          key: "ian",
          img: "ian.jpg",
        },
        {
          name: "Jel",
          key: "jel",
          img: "jel.jpg",
        },
        {
          name: "Arnout",
          key: "arnout",
          img: "arnout.jpg",
        },
      ],
    };
  },
  mounted() {
    this.dancer = this.dancers.find((d) => d.key == this.$route.params.name);

    function addOnBlurListener(onBlurCallback, onFocusCallback) {
      var hidden, visibilityState, visibilityChange; // check the visiblility of the page

      if (typeof document.hidden !== "undefined") {
        hidden = "hidden";
        visibilityChange = "visibilitychange";
        visibilityState = "visibilityState";
      } else if (typeof document.mozHidden !== "undefined") {
        hidden = "mozHidden";
        visibilityChange = "mozvisibilitychange";
        visibilityState = "mozVisibilityState";
      } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
        visibilityState = "msVisibilityState";
      } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
        visibilityState = "webkitVisibilityState";
      }

      if (
        typeof document.addEventListener === "undefined" ||
        typeof hidden === "undefined"
      ) {
        // not supported
      } else {
        document.addEventListener(
          visibilityChange,
          function () {
            switch (document[visibilityState]) {
              case "visible":
                if (onFocusCallback) onFocusCallback();
                break;
              case "hidden":
                if (onBlurCallback) onBlurCallback();
                break;
            }
          },
          false
        );
      }
    }

    function muteAudio() {
      // console.log("mute all audios...");
      var audios = document.getElementsByTagName("audio"),
        i,
        len = audios.length;
      for (i = 0; i < len; i++) {
        // console.log(audios[i]);
        audios[i].muted = true;
      }
    }

    function unMuteAudio() {
      // console.log("unmute all audios...");
      var audios = document.getElementsByTagName("audio"),
        i,
        len = audios.length;
      for (i = 0; i < len; i++) {
        // console.log(audios[i]);
        audios[i].muted = false;
      }
    }

    addOnBlurListener(muteAudio, unMuteAudio);
  },
  methods: {
    toggleMusic() {
      let audio = document.getElementById("music");
      this.isPlaying ? audio.pause() : audio.play();
      this.isPlaying = !this.isPlaying;
    },
  },
};
</script>

<style lang="scss" scoped>
.dance {
  height: 100%;

  @include for-desktop-up {
    min-height: 800px;
    max-width: 1200px;
    width: 70%;
    position: relative;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    height: 90%;
    height: 20px;
    margin: 0 auto;

    display: flex;
    align-items: center;
    box-shadow: 0 8px 32px 8px rgba(0, 0, 0, 0.1);
  }

  .sidebar {
    color: $text-color;
    text-align: left;

    display: none;
    width: 35%;
    overflow: hidden;
    height: 100%;
    background: $background-color;
    box-shadow: 8px 0px 12px 0px rgba(0, 0, 0, 0.1);
    z-index: 1;
    padding: 72px 24px 64px 64px;
    flex-direction: column;
    justify-content: space-between;

    @include for-desktop-up {
      display: flex;
    }

    &__logo {
      transition: all $standard-transition;

      img {
        width: 55%;
        min-width: 175px;
      }

      &:hover {
        transform: translateY(-4px);
      }
    }

    &__text {
      font-weight: bold;
      margin: 8px 0 72px 0;

      p {
        font-size: 18px;
        line-height: 18px;
        margin: 0;

        @include for-medium-desktop-up {
          font-size: 24px;
          line-height: 24px;
        }

        @include for-big-desktop-up {
          font-size: 36px;
          line-height: 36px;
        }
      }

      h1 {
        font-size: 48px;
        line-height: 48px;
        margin: 0 0 8px 0;

        @include for-medium-desktop-up {
          font-size: 56px;
          line-height: 56px;
        }

        @include for-big-desktop-up {
          font-size: 72px;
          line-height: 72px;
        }
      }
    }
  }
}

.text {
  position: absolute;
  top: 100px;
  font-size: 52px;
  line-height: 28px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);

  span {
    font-size: 19px;
  }

  @include for-desktop-up {
    display: none;
  }
}

.button {
  text-decoration: none;
  color: $text-color;
  border: none;
  position: absolute;
  z-index: 1;
  cursor: pointer;

  transition: background-color 100ms linear;

  &:active {
    transform: scale(1.05);
  }

  &.back {
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 0 0 4px 0;
    background-color: $accent-color;
    width: 70px;
    height: 70px;

    display: grid;
    place-content: center;

    img {
      transition: transform $standard-transition;
    }

    &:hover {
      background-color: lighten($accent-color, 10%);

      img {
        transform: translateX(-4px);
      }
    }

    &.mobile {
      @include for-desktop-up {
        display: none;
      }
    }

    &.desktop {
      position: relative;
      width: auto;
      display: flex;
      align-items: center;
      border-radius: 4px;
      width: 55%;
      min-width: 150px;
      height: 48px;

      img {
        margin-right: 18px;
        max-height: 12px;
      }
    }
  }

  &.ar {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 16px;
    border-radius: 4px;
    background-color: $secondary-color;
    margin: 0 auto;
    width: 70%;
    bottom: 30px;
    font-size: 16px;
    font-weight: bold;
    margin: auto 30px;
    width: calc(100% - 60px);
    box-shadow: 0 20px 56px -5px rgba(0, 0, 0, 0.2);

    img {
      margin-right: 16px;
    }

    &:hover {
      background-color: lighten($secondary-color, 5%);
    }
  }

  &.music {
    top: 0;
    right: 0;
    z-index: 1;

    display: grid;
    place-content: center;

    width: 70px;
    height: 70px;

    position: absolute;
    transition: all $standard-transition;

    img.turn-on {
      animation: wiggle 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s infinite;
    }

    span {
      display: none;
    }

    &:hover img {
      transform: scale(1.3);
      opacity: 0.5;
      animation: none;
    }

    @keyframes wiggle {
      0% {
        transform: translateY(0);
      }
      75% {
        transform: translateY(4px);
      }
      100% {
        transform: translateY(0);
      }
    }

    @include for-desktop-up {
      display: flex;
      align-items: center;
      color: black;
      top: unset;
      right: 46px;
      bottom: 28px;
      min-width: 200px;
      font-size: 17px;
      transition: all 0.1s linear;

      span {
        display: inline-block;
      }

      img {
        margin-left: 16px;

        &.turn-on {
          animation: none;
        }
      }

      &:hover {
        opacity: 0.5;

        img {
          transform: scale(1);
          animation: none;
        }
      }
    }
  }
}

model-viewer {
  background: white;
  margin: 0;
  height: 100vh;
  width: 100%;

  @include for-desktop-up {
    height: 100%;
    width: 65%;
  }
}

model-viewer > #ar-prompt {
  position: absolute;
  left: 50%;
  bottom: 175px;
  animation: elongate 2s infinite ease-in-out alternate;
  display: none;
}

model-viewer[ar-status="session-started"] > #ar-prompt {
  display: block;
}
</style>