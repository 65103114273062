<template>
  <main class="home">
    <div class="header">
      <img
        class="header__logo"
        alt="Poppr Christmas logo"
        :src="require('@/assets/christmas-logo.svg')"
      />
      <div class="header__intro">
        <h1>We wish you a merry christmas and a happy new year!</h1>
        <p>Here's to a joyful holiday season.</p>

        <div class="qr__container" v-if="!isMobile">
          <lottie
            class="hat"
            :options="defaultOptions"
            :height="400"
            :width="400"
            v-on:animCreated="handleAnimation"
          />
          <div class="qr__wrapper">
            <img
              alt="Poppr Holiday Experience QR"
              :src="require('@/assets/qr.png')"
            />
          </div>
          <p>
            For the augmented reality experience, please visit this site on your
            mobile device.
          </p>
        </div>
      </div>
    </div>

    <a class="dancer__cta animate--down" href="#dancers" v-if="isMobile">
      Want to see us dance?
      <img :src="require('@/assets/icon-down.svg')" alt="Arrow pointing down" />
    </a>

    <div class="dancer__grid" id="dancers">
      <h2 class="dancer__title">Want to see us dance?</h2>
      <router-link
        class="dancer__link"
        v-for="dancer in dancers"
        :key="dancer.key"
        :to="{ name: 'Dancer', params: { name: dancer.key, autoplay: true } }"
      >
        <div class="dancer__wrapper">
          <div class="dancer__circle">
            <img
              class="picture"
              :src="require(`@/assets/${dancer.img}`)"
              :alt="`Profile picture of ${dancer.name}`"
            />
            <div class="overlay">
              <p>Make me<br />dance!</p>
            </div>
          </div>
          <img class="crown" :src="require(`@/assets/icon-crown.svg`)" />
        </div>
        <h2 class="dancer__name">{{ dancer.name }}</h2>
      </router-link>
    </div>

    <img
      class="envelope"
      alt="Poppr Christmas Experience QR"
      :src="require('@/assets/envelope.svg')"
    />
  </main>
</template>

<script>
import Lottie from "vue-lottie";
import * as animationData from "@/assets/christmas-hat.json";

export default {
  name: "Home",
  components: { lottie: Lottie },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    mobileCheck() {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
      } else if (
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          ua
        )
      ) {
        return "mobile";
      }
      return "desktop";
    },
  },
  mounted() {
    let device = this.mobileCheck();
    this.isMobile = device !== "desktop" ? true : false;
    // console.log(device);
  },
  data() {
    return {
      defaultOptions: { animationData: animationData.default },
      animationSpeed: 1,
      anim: null,
      isMobile: false,
      dancers: [
        {
          name: "Steven",
          key: "steven",
          img: "steven.jpg",
        },
        {
          name: "Frederik",
          key: "frederik",
          song: "test.mp3",
          img: "frederik.jpg",
        },
        {
          name: "Bart",
          key: "bart",
          img: "bart.jpg",
        },
        {
          name: "Julie",
          key: "julie",
          img: "julie.jpg",
        },
        {
          name: "Ian",
          key: "ian",
          img: "ian.jpg",
        },
        {
          name: "Jel",
          key: "jel",
          img: "jel.jpg",
        },
        {
          name: "Arnout",
          key: "arnout",
          img: "arnout.jpg",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.home {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @include for-desktop-up {
    width: 70%;
    min-height: 800px;
    max-width: 1200px;
    height: 90%;
    position: relative;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    margin: 0 auto;
    background-color: $background-color;

    display: flex;
    align-items: center;
    box-shadow: 0 8px 32px 8px rgba(0, 0, 0, 0.1);

    .header {
      height: 70%;
      background-color: transparent;
    }

    #dancers {
      height: 30%;
    }
  }
}

.header {
  background-color: $background-color;
  padding: 0 32px;
  position: relative;
  width: 100%;
  color: $text-color;

  * {
    z-index: 2;
    position: relative;
  }

  &__logo {
    width: 50%;
    max-width: 250px;
    margin: 40px 0 50px 0;

    @include for-desktop-up {
      width: 15%;
      margin: 30px;
    }
  }

  &__intro {
    h1 {
      font-size: 22px;
      text-transform: uppercase;
      margin: 0 0 28px 0;
      line-height: 34px;

      @include for-tablet-portrait-up {
        font-size: 24px;
      }

      @include for-tablet-landscape-up {
        font-size: 28px;
        margin: 0 auto 28px auto;
      }

      @include for-desktop-up {
        font-size: 36px;
        line-height: 42px;
        max-width: 65%;
        margin: 0 auto 2vw auto;
      }

      @include for-medium-desktop-up {
        font-size: 42px;
        line-height: 52px;
      }

      @include for-big-desktop-up {
        font-size: 46px;
        line-height: 56px;
      }
    }

    p {
      font-size: 15px;
      line-height: 23px;
      margin: 0 0 50px 0;

      @include for-desktop-up {
        font-weight: bold;
        margin: 0 0 4vw 0;
      }
    }
  }

  .qr {
    &__container {
      -webkit-box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0.3);
      box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0.3);
      height: 200px;
      width: 200px;
      margin: 0;
      padding: 32px 10px;
      background-color: #fff;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      display: none;
      text-align: center;

      @include for-desktop-up {
        display: inline-flex;
      }

      .hat {
        position: absolute;
        top: -105px;
      }

      p {
        font-weight: normal;
        font-size: 12px;
        color: black;
        line-height: 16px;
        margin: 8px 0 0 0;
        padding: 0 30px;

        @include for-desktop-up {
          font-size: 9px;
          line-height: 10px;
        }

        @include for-medium-desktop-up {
          font-size: 11px;
          line-height: 12px;
        }
      }
    }

    &__wrapper {
      width: 60%;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    @include for-medium-desktop-up {
      &__container {
        margin: 0;
      }

      &__wrapper {
        width: 50%;
      }
    }
  }
}

.dancer {
  &__cta {
    text-decoration: none;
    color: $text-color-inverse;
    animation: down 2s cubic-bezier(0.86, 0, 0.07, 1) infinite;
    transition: all $standard-transition;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-weight: bold;

    margin: 30px 0;

    img {
      margin: 24px 0 0 0;
      max-width: 24px;
    }

    @keyframes down {
      0% {
        transform: translateY(0);
      }
      25% {
        transform: translateY(6px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }

  &__title {
    display: none;
    color: $text-color;
    position: absolute;
    left: 5%;
    top: -24px;
    margin: 0;

    @include for-desktop-up {
      display: block;
    }
  }

  &__grid {
    z-index: 2;
    padding: 56px 36px;
    display: flex;
    grid-gap: 36px;
    width: 100%;
    display: flex;
    justify-content: center;
    transition: all $standard-transition;
    flex-wrap: wrap;
    position: relative;

    @include for-desktop-up {
      flex-wrap: nowrap;
      padding: 36px 36px 12px 36px;
      justify-content: space-around;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;

    h2 {
      text-transform: uppercase;
    }

    @include for-desktop-up {
      // width: (100% / 10);
    }
  }

  &__name {
    color: $text-color-inverse;
    font-size: 15px;

    @include for-desktop-up {
      color: $text-color;
    }
  }

  &__wrapper {
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;

    width: 130px;
    height: 130px;
    background-color: transparent;

    transition: all $standard-transition;

    @include for-desktop-up {
      width: 80px;
      height: 80px;
    }

    @include for-medium-desktop-up {
      width: 100px;
      height: 100px;
    }

    @include for-big-desktop-up {
      width: 120px;
      height: 120px;
    }

    .dancer__circle {
      height: 100%;
      width: 100%;
      overflow: hidden;
      border-radius: 50%;
      position: relative;

      display: grid;
      place-items: center;

      .picture {
        object-fit: cover;
        object-position: 0px 0px;
        height: 100%;
        width: 100%;
        transition: transform $standard-transition;
      }

      .overlay {
        position: absolute;
        background-color: rgba($accent-color, 0.5);
        z-index: 1;
        opacity: 0;

        left: 50%;
        top: 50%;
        width: 110%;
        height: 110%;
        transform: translate(-50%, -50%);

        transition: all $standard-transition;

        display: grid;
        place-content: center;

        p {
          color: $text-color;
          font-weight: bold;
          font-size: 15px;
          line-height: 17px;

          @include for-desktop-up {
            font-size: 12px;
            line-height: 14px;
          }

          @include for-medium-desktop-up {
            font-size: 14px;
            line-height: 16px;
          }

          @include for-big-desktop-up {
            font-size: 15px;
            line-height: 17px;
          }
        }
      }
    }

    .crown {
      position: absolute;
      top: -32px;
      transform: scale(0.4);
      opacity: 0;
      transition: all $standard-transition;
    }

    &:hover {
      transform: translateY(-4px);

      .overlay {
        opacity: 1;
      }

      .crown {
        opacity: 1;
        top: -56px;
        z-index: 1;
      }
    }
  }
}

img.envelope {
  display: block;
  z-index: 1;
  position: absolute;
  width: 100%;
  max-height: 250px;
  -webkit-filter: drop-shadow(0 0 42px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 0 42px rgba(0, 0, 0, 0.2));

  @include for-desktop-up {
    max-height: unset;
  }
}
</style>